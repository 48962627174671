import React from 'react'

export const RotateIcon = ({
  style,
  fill,
}: {
  style: React.CSSProperties
  fill: string
}) => (
  <svg
    className="RotateIcon"
    style={style}
    viewBox="0 0 55 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.608 16.9164C53.2837 16.4115 52.8193 16.0141 52.272 15.7756C51.7254 15.5371 51.1207 15.4676 50.5345 15.5765L47.9969 16.0327C45.3354 9.09815 39.7724 3.71263 32.8068 1.32705C25.8404 -1.05854 18.1875 -0.199089 11.9081 3.67394C5.62849 7.54694 1.36899 14.0354 0.275842 21.3918C-0.816638 28.7475 1.36903 36.2155 6.2453 41.7844C11.1216 47.3533 18.1869 50.45 25.5381 50.2408C32.8896 50.0317 39.7715 46.5383 44.3307 40.7016L39.6506 36.9087H39.6499C36.2068 41.2744 31.0452 43.8948 25.5269 44.0789C20.0086 44.2629 14.6876 41.9919 10.967 37.8654C7.24712 33.7388 5.50108 28.1707 6.19004 22.6297C6.87824 17.0887 9.9326 12.1307 14.5468 9.06424C19.1617 5.99756 24.8727 5.12964 30.1745 6.69021C35.4754 8.25 39.8355 12.0814 42.1032 17.1734L39.9323 17.573L39.9316 17.5723C39.15 17.7134 38.4547 18.1603 37.9965 18.8163C37.5385 19.4723 37.3544 20.2844 37.4846 21.0765C37.6149 21.8685 38.0482 22.5768 38.6911 23.0481L45.035 27.4969C45.6928 27.9431 46.4971 28.1114 47.2759 27.9682C48.0547 27.8242 48.7479 27.3795 49.2081 26.7271L53.6064 20.3103C53.9385 19.8082 54.1162 19.2174 54.1162 18.6137C54.1162 18.0093 53.9385 17.4184 53.6064 16.9164L53.608 16.9164Z"
      fill={fill}
    />
  </svg>
)

export const CaretUp = ({
  style,
  fill,
}: {
  style: React.CSSProperties
  fill: string
}) => (
  <svg
    className="CaretUp"
    style={style}
    viewBox="0 0 386 216"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39.858 209.03L192.518 56.3698L345.178 209.03C351.072 214.928 359.67 217.229 367.729 215.073C375.784 212.913 382.077 206.62 384.237 198.565C386.393 190.506 384.092 181.909 378.194 176.014L209.024 6.8438C204.649 2.461 198.708 0 192.516 0C186.324 0 180.383 2.4609 176.008 6.8438L6.83796 176.014C0.939564 181.908 -1.36125 190.506 0.794949 198.565C2.95515 206.619 9.24806 212.913 17.303 215.073C25.3616 217.229 33.959 214.928 39.854 209.03H39.858Z"
      fill={fill}
    />
  </svg>
)

export const CrossIcon = ({
  style,
  fill,
}: {
  style: React.CSSProperties
  fill: string
}) => (
  <div
    className="CrossIcon"
    style={{
      color: fill,
      fontWeight: 'bold',
      fontSize: '24px',
      ...style,
    }}
  >
    {'×'}
  </div>
)
